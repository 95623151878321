import {
    capture, issueDetail, issueForm, navigations, adminUserRoles,
    adminPermissions, adminRoles, adminUsers, adminUserInvites
} from '~~/types/constants';

const pathForIssue = '/issue';
const startPathForIssueForm = '/issue-form';
const startPathForIssueDetails = '/issue/details';
const pathForIssueCapture = '/issue/capture';
const pathForAdminUsers = '/admin/users';
const pathForAdminRoles = '/admin/roles';
const pathForAdminUserInvites = '/admin/userinvites';
const pathForAdminPermissions = '/admin/permissions';
const pathForAdminUserRoles = '/admin/user/roles';

export const useBreadcrumbs = () => {
    const route = useRoute();
    const breadcrumbs:Ref<Array<{ name: string; path: string; }>> = ref([ ]);
    function getBreadcrumbs(currRoute: string): any[ ] {
        if (currRoute === navigations[0].href)
            return [ navigations[0] ];
        if (currRoute === navigations[1].href)
            return [ navigations[1] ];
        // if (currRoute === navigations[2].href)
        //     return [ navigations[2] ];
        if (currRoute === pathForIssue)
            return [ navigations[1] ];
        if (currRoute.startsWith(startPathForIssueForm))
            return [ issueForm ];
        if (currRoute.startsWith(startPathForIssueDetails))
            return [ navigations[1], issueDetail ];
        if (currRoute === pathForIssueCapture)
            return [ capture ];
        if (currRoute.startsWith(pathForAdminUserRoles))
            return [ adminUserRoles ];
        if (currRoute === pathForAdminPermissions)
            return [ adminPermissions ];
        if (currRoute === pathForAdminRoles)
            return [ adminRoles ];
        if (currRoute === pathForAdminUsers)
            return [ adminUsers ];
        if (currRoute === pathForAdminUserInvites)
            return [ adminUserInvites ];
        else
            return [];
    }

    watch(() => ({
        path: route.path,
        name: route.name,
        meta: route.meta,
        matched: route.matched
    }), (route) => {
        breadcrumbs.value = getBreadcrumbs(route.path);
    }, {
        immediate: true
    });

    return {
        breadcrumbs
    };
};
